<template>
  <div class="py-1">
    <div v-if="loading" class="spinner-overlay">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>
    <b-card no-body>
      <b-card-header>
        <div>
          <h4 class="font-weight-bolder">Create Lecture</h4>
          <p>You can create a new lecture here for OXAR teacher.</p>
        </div>
        <div>
          <b-button type="submit" variant="colorLightBlue" rounded class="mr-1">
            <span class="text-colorBlue">Cancel</span>
          </b-button>
          <b-button type="submit" variant="colorBlue" rounded @click="validateAndSubmit()">
            <span class="text-colorWhite">Generate Lecture</span>
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <hr />
        <validation-observer ref="lectureCreateFormValidation">
          <b-form prevent.default>
            <validation-provider rules="required" v-slot="{ errors }" name="First Lecture">
              <b-form-group class="py-1 mb-0">
                <b-form-checkbox v-model="first_lecture" id="first-lecture" name="first-lecture">
                  <span class="font-weight-bolder">First Lecture</span>
                </b-form-checkbox>
              </b-form-group>
              <p class="text-danger">{{ errors[0] }}</p>
            </validation-provider>
            <hr />
            <b-form-row class="py-1">
              <b-col>
                <span class="font-weight-bolder">Class Name*</span>
              </b-col>
              <b-col>
                <validation-provider rules="required" v-slot="{ errors }" name="Class Name">
                  <b-form-group class="mb-0">
                    <v-select
                      label="name"
                      v-model="classe"
                      placeholder="Select Class"
                      :options="classes"
                      autoscroll
                      :clearable="true"
                    ></v-select>
                  </b-form-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                </validation-provider>
              </b-col>
            </b-form-row>
            <hr />
            <b-form-row class="py-1">
              <b-col>
                <span class="font-weight-bolder">Subject*</span>
              </b-col>
              <b-col>
                <validation-provider rules="required" v-slot="{ errors }" name="Subject">
                  <b-form-group class="mb-0">
                    <v-select
                      label="name"
                      v-model="subject"
                      placeholder="Select Subject"
                      :options="subjects"
                      autoscroll
                      :clearable="true"
                    ></v-select>
                  </b-form-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                </validation-provider>
              </b-col>
            </b-form-row>
            <hr />
            <validation-provider rules="required" v-slot="{ errors }" name="Lecture Title">
              <b-form-row class="py-1">
                <b-col>
                  <span class="font-weight-bolder">Lecture Title*</span>
                </b-col>
                <b-col>
                  <b-form-group class="mb-0">
                    <b-form-input id="lectureTitle" placeholder="Lecture Title" v-model="title" />
                    <p class="text-danger">{{ errors[0] }}</p>
                  </b-form-group>
                </b-col>
              </b-form-row>
              
            </validation-provider>
            <hr />
            <b-form-row class="py-1">
              <b-col>
                <span class="font-weight-bolder">Lesson Number*</span>
              </b-col>
              <b-col>
                <validation-provider rules="required" v-slot="{ errors }" name="Lesson Number">
                  <b-form-group class="mb-0">
                    <b-form-input id="lessonNumber" v-model="lesson_number" placeholder="Lesson Number" />
                  </b-form-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                </validation-provider>
              </b-col>
            </b-form-row>
            <hr />
            <b-form-row class="py-1">
              <b-col>
                <span class="font-weight-bolder">Lecture Duration*</span>
              </b-col>
              <b-col>
                <validation-provider rules="required" v-slot="{ errors }" name="Lecture Duration">
                  <b-form-group class="mb-0">
                    <b-form-input id="lectureDuration" v-model="duration" placeholder="Add Minutes" />
                  </b-form-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                </validation-provider>
              </b-col>
            </b-form-row>
            <hr />
            <b-form-row class="py-1">
              <b-col>
                <span class="font-weight-bolder">Key Concepts*</span>
              </b-col>
              <b-col>
                <b-form-group class="mb-0">
                  <div class="tags-input">
                    <span v-for="(tag, index) in key_concepts" :key="index" class="tag">
                      {{ tag }}
                      <button @click.prevent="removeTag(index)">x</button>
                    </span>
                    <input
                      type="text"
                      class="newTagClass"
                      v-model="newTag"
                      @keyup.enter="addTag"
                      @keyup="checkForComma"
                      placeholder="Add a tag"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <hr />
            <validation-provider rules="required|min:500" v-slot="{ errors }" name="Lesson Content">
              <b-form-group class="mt-2">
                <span class="font-weight-bolder">Lesson Content*</span>
                <b-form-textarea v-model="lesson_content" />
                <p class="text-danger">{{ errors[0] }}</p>
              </b-form-group>
            </validation-provider>
            <b-form-group class="py-1 mb-0">
              <b-form-checkbox
                id="include-real-life"
                name="include-real-life"
                v-model="includeRealLife"
              >
                <span class="font-weight-bolder">Include real-life examples</span>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="mt-2" v-if="includeRealLife">
              <quill-editor v-model="example" :options="option" />
            </b-form-group>
            <b-form-group class="py-1 mb-0">
              <b-form-checkbox
                id="include-case-studies"
                name="include-case-studies"
                v-model="includeCaseStudies"
              >
                <span class="font-weight-bolder">Include case studies</span>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="mt-2" v-if="includeCaseStudies">
              <quill-editor v-model="case_studies" :options="option" />
            </b-form-group>
            <b-form-group class="py-1 mb-0">
              <b-form-checkbox
                id="add-exercise-content"
                name="add-exercise-content"
                v-model="addExerciseContent"
              >
                <span class="font-weight-bolder">Add Exercise content</span>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="mt-2" v-if="addExerciseContent">
              <b-form-row class="py-1">
                <b-col>
                  <span class="font-weight-bolder">Exercise Placement*</span>
                </b-col>
                <b-col>
                  <b-form-group class="mb-0">
                    <v-select
                      :options="['Math', 'English']"
                      placeholder="Exercise Placement"
                      v-model="excercise_placement"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row class="py-1">
                <b-col>
                  <span class="font-weight-bolder"
                    >Exercise And Activity Duration*</span
                  >
                </b-col>
                <b-col>
                  <b-form-group class="mb-0">
                    <v-select
                      :options="['Math', 'English']"
                      placeholder="Exercise And Activity  Duration"
                      v-model="exercise_duration"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
              <quill-editor v-model="exercise_description" :options="option"  class="py-1" />
              
            </b-form-group>
          </b-form>
        </validation-observer>  
      </b-card-body>
      <b-card-footer footer-class="d-flex justify-content-end">
        <div>
          <b-button
            type="submit"
            variant="colorLightBlue"
            rounded
            class="mr-1"
            @click="
              () => {
                this.$router.push({ name: 'InstructorDashboard' });
              }
            "
          >
            <span class="text-colorBlue">Cancel</span>
          </b-button>
          <b-button
            type="submit"
            variant="colorBlue"
            rounded
            @click="validateAndSubmit()"
          >
            <span class="text-colorWhite">Generate Lecture</span>
          </b-button>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";
import axios from 'axios';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";


export default {
  components: {
    quillEditor,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      required,
      option: [],
      addLessonContent: false,
      includeRealLife: false,
      includeCaseStudies: false,
      addExerciseContent: false,
      first_lecture : false,
      classe : "",
      subject : "",
      title : "",
      lesson_number : "",
      duration : "",
      key_concepts : [],
      lesson_content : "",
      example : "",
      case_studies : "",
      excercise_placement : "",
      exercise_duration : "",
      exercise_description : "",
      transcript_file : "",
      transcript_with_mcqs_file : "",
      mcqs_file_json : "",
      transcript_length : "",
      newTag: "",
      classes: [],
      subjects: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      createLecture : "appData/createLecture",
      getClassDropDown: "appData/getClassDropDown",
      getSubjectDropDown: "appData/getSubjectDropDown",
    }),

    addTag() {
      const trimmedTag = this.newTag.trim();
      if (trimmedTag && !this.key_concepts.includes(trimmedTag)) {
        this.key_concepts.push(trimmedTag); // Push tag to the array
        this.newTag = ""; // Clear input after adding the tag
      }
    },
    checkForComma() {
      // Automatically add tag if comma is detected
      if (this.newTag.includes(",")) {
        this.newTag = this.newTag.replace(/,/g, "").trim();
        this.addTag();
      }
    },
    removeTag(index) {
      this.key_concepts.splice(index, 1); // Remove tag by index
    },

    async validateAndSubmit() {
      // Validate the whole form before submission
      const isValid = await this.$refs.lectureCreateFormValidation.validate();
      if (isValid) {
        this.addLecture();
      } else {
        console.log("asdfasdf");
      }
    },

    async addLecture() {
      try {
        this.loading = true;
        // Send initial request to generate lecture
        const response = await axios.post('http://13.60.91.35/generate_lecture', {
          "subject": this.subject.name,
          "lesson_title": this.title,
          "lesson_content": this.lesson_content,
          "keywords": this.key_concepts,
          "students_age": "18-24",
          "lecture_duration": this.duration,
          "previous_lessons": [
            "Business and the Business Environment",
            "Marketing Processes and Planning"
          ],
          "teacher_name": this.getLoggedInUser.name,
          "lecture_part_length": 1200,
          "first_lecture": this.first_lecture,
          "special_instructions": "Be interactive and polite",
          "institution_name": this.getLoggedInUser.institute_data.name
        });

        // Download the files from URLs
        const transcriptFile = await this.downloadFile(response.data.transcript_json_file);
        const transcriptWithMcqsFile = await this.downloadFile(response.data.transcript_with_mcqs_file);
        const mcqsFileJson = await this.downloadFile(response.data.mcqs_file_json);

        // Prepare the FormData object
        const formData = new FormData();
        formData.append("first_lecture", this.first_lecture);
        formData.append("classe", this.classe.id);
        formData.append("subject", this.subject.id);
        formData.append("title", this.title);
        formData.append("lesson_number", this.lesson_number);
        formData.append("duration", this.duration);
        formData.append("address", this.address);
        formData.append("key_concepts", this.key_concepts);
        formData.append("lesson_content", this.lesson_content);
        formData.append("example", this.example);
        formData.append("case_studies", this.case_studies);
        formData.append("excercise_placement", this.excercise_placement);
        formData.append("exercise_duration", this.exercise_duration);
        formData.append("exercise_description", this.exercise_description);

        // Append the downloaded files
        formData.append("transcript_file", transcriptFile);
        formData.append("transcript_with_mcqs_file", transcriptWithMcqsFile);
        formData.append("mcqs_file_json", mcqsFileJson);
        formData.append("transcript_length", this.transcript_length);

        formData.append("created_by", this.getLoggedInUser.id); 
        formData.append("updated_by", this.getLoggedInUser.id); 

        // Create the lecture
        const res = await this.createLecture(formData);
        if (res.status === 201) {
          this.$swal({
            title: "Lecture created successfully",
            icon: "success",
          });
          this.$router.push({ name: "InstructorDashboard" });
        }
      } catch (error) {
        console.error('Error:', error);
        this.displayError(error);
      } finally {
        this.loading = false;  // Hide spinner
      }
    },


    async downloadFile(url) {
      try {
        const response = await axios.get(url, {
          responseType: 'blob', // important for downloading file
        });
        const fileName = url.split('?')[0].split('/').pop(); // Extract file name from URL
        return new File([response.data], fileName, { type: response.data.type });
      } catch (error) {
        console.error('Error downloading file:', error);
        throw error;
      }
    },

  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" })
  },
  async mounted(){
    const res_classes = await this.getClassDropDown();
    this.classes = res_classes.data;

    const res_subject = await this.getSubjectDropDown();
    this.subjects = res_subject.data;
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";

.tags-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
}

.tag {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
}

.tag button {
  background: transparent;
  border: none;
  color: white;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
}

.newTagClass {
  border: none;
  outline: none;
  flex: 1;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* White background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* To ensure it's on top of everything */
}

</style>
